import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ColorPickerModule } from 'primeng/colorpicker';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { KeyFilterModule } from 'primeng/keyfilter';
import { PasswordModule } from 'primeng/password';
import { PickListModule } from 'primeng/picklist';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { TooltipModule } from 'primeng/tooltip';
import { LayoutModule } from '../layout/layout.module';

import { BotaoTelaPesquisaComponent } from './botao-tela-pesquisa/botao-tela-pesquisa.component';

import { BadgeComponent } from './components/badge/badge.component';
import { BotaoFormularioComponent } from './components/botao-formulario/botao-formulario.component';

import { CepComponent } from './components/cep/cep.component';
import { ClienteCadastroRapidoComponent } from './components/cliente-cadastro-rapido/cliente-cadastro-rapido.component';
import { ContainerInputComponent } from './components/container-input/container-input.component';
import { ErrorComponent } from './components/error/error.component';
import { FormBoxComponent } from './components/form-box/form-box.component';
import { AlmoxarifadoAutoCompleteComponent } from './components/form/auto-complete/almoxarifado-auto-complete/almoxarifado-auto-complete.component';
import { AtividadeClienteFornecedorAutoCompleteComponent } from './components/form/auto-complete/atividade-cliente-fornecedor-auto-complete/atividade-cliente-fornecedor-auto-complete.component';
import { CidadeAutoCompleteComponent } from './components/form/auto-complete/cidade-auto-complete/cidade-auto-complete.component';
import { ClienteAutoCompleteComponent } from './components/form/auto-complete/cliente-auto-clomplete/cliente-auto-complete.component';
import { ColaboradorAutoCompleteComponent } from './components/form/auto-complete/colaborador-auto-complete/colaborador-auto-complete.component';
import { ContaCaixaAutoCompleteComponent } from './components/form/auto-complete/conta-caixa-auto-complete/conta-caixa-auto-complete.component';
import { DocumentoOrigemAutoCompleteComponent } from './components/form/auto-complete/documento-origem-auto-complete/documento-origem-auto-complete.component';
import { EmpresaAutoCompleteComponent } from './components/form/auto-complete/empresa-auto-complete/empresa-auto-complete.component';
import { FornecedorAutoCompleteComponent } from './components/form/auto-complete/fornecedor-auto-complete/fornecedor-auto-complete.component';
import { GrupoProdutoAutoCompleteComponent } from './components/form/auto-complete/grupo-produto-auto-complete/grupo-produto-auto-complete.component';
import { GrupoTributarioAutoCompleteComponent } from './components/form/auto-complete/grupo-tributario-auto-complete/grupo-tributario-auto-complete.component';
import { MarcaProdutoAutoCompleteComponent } from './components/form/auto-complete/marca-produto-auto-complete/marca-produto-auto-complete.component';
import { NaturezaFinanceiraAutoCompleteComponent } from './components/form/auto-complete/natureza-financeira-auto-complete/natureza-financeira-auto-complete.component';
import { OperacaoFiscalAutoCompleteComponent } from './components/form/auto-complete/operacao-fiscal-auto-complete/operacao-fiscal-auto-complete.component';
import { ProdutoAutoCompleteComponent } from './components/form/auto-complete/produto-auto-complete/produto-auto-complete.component';
import { RegiaoAutoCompleteComponent } from './components/form/auto-complete/regiao-auto-complete/regiao-auto-complete.component';
import { SituacaoClienteFornecedorAutoCompleteComponent } from './components/form/auto-complete/situacao-cliente-fornecedor-auto-complete/situacao-cliente-fornecedor-auto-complete.component';
import { SubGrupoProdutoAutoCompleteComponent } from './components/form/auto-complete/sub-grupo-produto-auto-complete/sub-grupo-produto-auto-complete.component';
import { TabelaPrecoAutoCompleteComponent } from './components/form/auto-complete/tabela-preco-auto-complete/tabela-preco-auto-complete.component';
import { TipoRecebimentoAutoCompleteComponent } from './components/form/auto-complete/tipo-recebimento-auto-complete/tipo-recebimento-auto-complete.component';
import { UnidadeMedidaAutoCompleteComponent } from './components/form/auto-complete/unidade-medida-auto-complete/unidade-medida-auto-complete.component';
import { VendedorAutoCompleteComponent } from './components/form/auto-complete/vendedor-auto-complete/vendedor-auto-complete.component';

import { IboxContentComponent } from './components/ibox-content/ibox-content.component';
import { IconButtonComponent } from './components/icon-button/icon-button.component';

import { PessoaFisicaFormComponent } from '../view/cadastros/shared/pessoa-fisica-form/pessoa-fisica-form.component';
import { PessoaJuridicaFormComponent } from '../view/cadastros/shared/pessoa-juridica-form/pessoa-juridica-form.component';

import { InputUploadComponent } from './components/input-upload/input-upload.component';

import { DataViewModule } from 'primeng/dataview';
import { DividerModule } from 'primeng/divider';
import { GalleriaModule } from 'primeng/galleria';
import { SkeletonModule } from 'primeng/skeleton';
import { PessoaEnderecoFormComponent } from '../view/cadastros/shared/pessoa-endereco-form/pessoa-endereco-form.component';
import { BotaoRedeSocialComponent } from './components/botao-rede-social/botao-rede-social.component';
import { CadRapidoProdutoComponent } from './components/cad-rapido-produto/cad-rapido-produto.component';
import { CnpjComponent } from './components/cnpj/cnpj.component';
import { ContainerManagerLoginComponent } from './components/container-manager-login/container-manager-login.component';
import { DividerComponent } from './components/divider/divider.component';
import { FormDialogComponent } from './components/form-dialog/form-dialog.component';
import { CaixaAutoCompleteComponent } from './components/form/auto-complete/caixa-auto-complete/caixa-auto-complete.component';
import { CondicoesAutoCompleteComponent } from './components/form/auto-complete/condicoes-auto-complete/condicoes-auto-complete.component';
import { CorAutoCompleteComponent } from './components/form/auto-complete/cor-auto-complete/cor-auto-complete.component';
import { PessoaAutoCompleteComponent } from './components/form/auto-complete/pessoa-auto-complete/pessoa-auto-complete.component';
import { ProdutoEstoqueAutoCompleteComponent } from './components/form/auto-complete/produto-estoque-auto-complete/produto-estoque-auto-complete.component';
import { ProdutoRowExpandComponent } from './components/form/auto-complete/produto-row-expand/produto-row-expand.component';
import { ProdutoTamanhoAutoCompleteComponent } from './components/form/auto-complete/produto-tamanho-auto-complete/produto-tamanho-auto-complete.component';
import { TipoPagamentoAutoCompleteComponent } from './components/form/auto-complete/tipo-pagamento-auto-complete/tipo-pagamento-auto-complete.component';
import { TransportadoraAutoComplenteComponent } from './components/form/auto-complete/transportadora-auto-complente/transportadora-auto-complente.component';
import { UsuarioAutoCompleteComponent } from './components/form/auto-complete/usuario-auto-complete/usuario-auto-complete.component';
import { VeiculoAutoCompleteComponent } from './components/form/auto-complete/veiculo-auto-complete/veiculo-auto-complete.component';
import { LabelTotalComponent } from './components/form/label-total/label-total.component';
import { ImagemUploadComponent } from './components/imagem-upload/imagem-upload.component';
import { TablePaginadaComponent } from './components/table-paginada/table-paginada.component';
import { TableComponent } from './components/table/table.component';
import { TelaPesquisaComponent } from './components/tela-pesquisa/tela-pesquisa.component';
import { BaseTemplateDirective } from './diretivas/base-template.directive';
import { CnpjPipe } from './pipes/cnpj.pipe';
import { CompletarZerosPipe } from './pipes/completar-zeros.pipe';
import { CpfPipe } from './pipes/cpf.pipe';
import { CpfCnpjPipe } from './pipes/cpfCnpj.pipe';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    SplitButtonModule,
    InputTextModule,
    InputTextareaModule,
    InputNumberModule,
    InputMaskModule,
    TableModule,
    FormsModule,
    ReactiveFormsModule,
    AutoCompleteModule,
    CalendarModule,
    TieredMenuModule,
    FileUploadModule,
    PickListModule,
    DropdownModule,
    DialogModule,
    DividerModule,
    GalleriaModule,
    DataViewModule,
    SplitButtonModule,
  ],
  declarations: [
    BotaoFormularioComponent,
    BotaoTelaPesquisaComponent,
    ContainerInputComponent,
    ErrorComponent,
    CadRapidoProdutoComponent,
    TelaPesquisaComponent,
    CompletarZerosPipe,
    CpfCnpjPipe,
    CpfPipe,
    CnpjPipe,
    CepComponent,
    IconButtonComponent,
    BaseTemplateDirective,
    BadgeComponent,
    FormBoxComponent,
    InputUploadComponent,
    IboxContentComponent,
    TablePaginadaComponent,
    ClienteAutoCompleteComponent,
    VendedorAutoCompleteComponent,
    GrupoProdutoAutoCompleteComponent,
    SubGrupoProdutoAutoCompleteComponent,
    UnidadeMedidaAutoCompleteComponent,
    MarcaProdutoAutoCompleteComponent,
    AlmoxarifadoAutoCompleteComponent,
    GrupoTributarioAutoCompleteComponent,
    ColaboradorAutoCompleteComponent,
    TableComponent,
    ContaCaixaAutoCompleteComponent,
    NaturezaFinanceiraAutoCompleteComponent,
    FornecedorAutoCompleteComponent,
    DocumentoOrigemAutoCompleteComponent,
    ProdutoAutoCompleteComponent,
    RegiaoAutoCompleteComponent,
    EmpresaAutoCompleteComponent,
    CidadeAutoCompleteComponent,
    TipoRecebimentoAutoCompleteComponent,
    PessoaEnderecoFormComponent,
    ClienteCadastroRapidoComponent,
    PessoaFisicaFormComponent,
    PessoaJuridicaFormComponent,
    TabelaPrecoAutoCompleteComponent,
    AtividadeClienteFornecedorAutoCompleteComponent,
    SituacaoClienteFornecedorAutoCompleteComponent,
    OperacaoFiscalAutoCompleteComponent,
    LabelTotalComponent,
    DividerComponent,
    VeiculoAutoCompleteComponent,
    TransportadoraAutoComplenteComponent,
    TipoPagamentoAutoCompleteComponent,
    CorAutoCompleteComponent,
    ProdutoEstoqueAutoCompleteComponent,
    CaixaAutoCompleteComponent,
    ImagemUploadComponent,
    CondicoesAutoCompleteComponent,
    UsuarioAutoCompleteComponent,
    PessoaAutoCompleteComponent,
    ProdutoTamanhoAutoCompleteComponent,
    CnpjComponent,
    ProdutoRowExpandComponent,
    FormDialogComponent,
    BotaoRedeSocialComponent,
    ContainerManagerLoginComponent,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CepComponent,
    CnpjComponent,
    BotaoTelaPesquisaComponent,
    BotaoFormularioComponent,
    ContainerInputComponent,
    LayoutModule,
    ButtonModule,
    IconButtonComponent,
    DropdownModule,
    InputTextModule,
    AutoCompleteModule,
    InputTextareaModule,
    TooltipModule,
    ButtonModule,
    TableModule,
    TabViewModule,
    AutoCompleteModule,
    DropdownModule,
    CalendarModule,
    DialogModule,
    KeyFilterModule,
    ProgressSpinnerModule,
    FileUploadModule,
    InputMaskModule,
    FieldsetModule,
    SplitButtonModule,
    EditorModule,
    CadRapidoProdutoComponent,
    TelaPesquisaComponent,
    CompletarZerosPipe,
    CpfCnpjPipe,
    CpfPipe,
    CnpjPipe,
    InputNumberModule,
    FormBoxComponent,
    TieredMenuModule,
    BadgeComponent,
    InputUploadComponent,
    PickListModule,
    RadioButtonModule,
    CheckboxModule,
    IboxContentComponent,
    ColorPickerModule,
    VendedorAutoCompleteComponent,
    ClienteAutoCompleteComponent,
    BaseTemplateDirective,
    TablePaginadaComponent,
    GrupoProdutoAutoCompleteComponent,
    SubGrupoProdutoAutoCompleteComponent,
    UnidadeMedidaAutoCompleteComponent,
    MarcaProdutoAutoCompleteComponent,
    AlmoxarifadoAutoCompleteComponent,
    GrupoTributarioAutoCompleteComponent,
    ColaboradorAutoCompleteComponent,
    ContaCaixaAutoCompleteComponent,
    CaixaAutoCompleteComponent,
    NaturezaFinanceiraAutoCompleteComponent,
    FornecedorAutoCompleteComponent,
    DocumentoOrigemAutoCompleteComponent,
    TabelaPrecoAutoCompleteComponent,
    AtividadeClienteFornecedorAutoCompleteComponent,
    SituacaoClienteFornecedorAutoCompleteComponent,
    OperacaoFiscalAutoCompleteComponent,
    PessoaAutoCompleteComponent,
    TableComponent,
    PasswordModule,
    ProdutoAutoCompleteComponent,
    RegiaoAutoCompleteComponent,
    EmpresaAutoCompleteComponent,
    CidadeAutoCompleteComponent,
    TipoRecebimentoAutoCompleteComponent,
    PessoaEnderecoFormComponent,
    ClienteCadastroRapidoComponent,
    PessoaFisicaFormComponent,
    PessoaJuridicaFormComponent,
    LabelTotalComponent,
    VeiculoAutoCompleteComponent,
    TransportadoraAutoComplenteComponent,
    DividerComponent,
    TipoPagamentoAutoCompleteComponent,
    CorAutoCompleteComponent,
    ProdutoEstoqueAutoCompleteComponent,
    ImagemUploadComponent,
    CondicoesAutoCompleteComponent,
    UsuarioAutoCompleteComponent,
    GalleriaModule,
    ProdutoTamanhoAutoCompleteComponent,
    DataViewModule,
    SkeletonModule,
    FormDialogComponent,
    ContainerManagerLoginComponent,
  ],
})
export class SharedModule {}
