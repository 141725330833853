import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, firstValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';
import { NomeResponse } from '../shared/models/nome.model';
import { StorageKeys } from '../util/storage-keys';
import { JwtHelperService } from './jwt-helper';
import { JwtPayload } from './model/jwtPayload';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private url: string;
  jwtPayload: JwtPayload | null = null;
  redirectUrl?: string;
  private _isAuthenticated = new ReplaySubject<boolean>(1);

  // @ts-ignore
  Buffer = window.Buffer || require('buffer').Buffer;

  constructor(private http: HttpClient, private jwtHelper: JwtHelperService) {
    this.url = `${environment.apiUrl}/oauth/token`;
    this.carregarToken();
  }

  solicitarRecuperarSenha(email: string) {
    return this.http.post<void>(
      `${environment.apiUrl}/cadastros/administrativo/usuarios/enviar-resert-senha`,
      { email }
    );
  }

  resetarSenha(email: string, senha: string, token: string) {
    return this.http.post<void>(
      `${environment.apiUrl}/cadastros/administrativo/usuarios/resert-senha`,
      { email, senha, token }
    );
  }

  login(usuario: string, senha: string): Promise<void> {
    const senhaClient = this.encodeBase64('appweb:w3bcl13nt');
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization', `Basic ${senhaClient}`);

    const body = `username=${usuario}&password=${senha}&grant_type=password`;

    return this.http
      .post<any>(this.url, body, { headers, withCredentials: true })
      .toPromise()
      .then((response) => {
        this.setAuthState({
          token: response.access_token,
          isAuthenticated: true,
        });
      })
      .catch((response) => {
        if (response.status === 400) {
          if (response.error.error === 'invalid_grant') {
            return Promise.reject('Usuário ou senha inválida!');
          }
        }

        return Promise.reject(response);
      });
  }

  logout() {
    return firstValueFrom(
      this.http.delete(`${environment.apiUrl}/tokens/revoke`, {
        withCredentials: true,
      })
    ).then(() => {
      this.limparAccessToken();
    });
  }

  obterNovoAccessToken() {
    const senhaClient = this.encodeBase64('appweb:w3bcl13nt');
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization', `Basic ${senhaClient}`);

    const body = 'grant_type=refresh_token';

    return firstValueFrom(
      this.http.post<any>(this.url, body, { headers, withCredentials: true })
    )
      .then((response) => {
        this.setAuthState(
          { token: response.access_token, isAuthenticated: true },
          true
        );

        return Promise.resolve(null);
      })
      .catch((response) => {
        this.setAuthState({ token: undefined, isAuthenticated: false });
        return Promise.resolve(null);
      });
  }

  isAccessTokenInvalido() {
    const token = localStorage.getItem(StorageKeys.AUTH_TOKEN);

    return !token || this.jwtHelper.isTokenExpired(token);
  }

  limparAccessToken() {
    this._isAuthenticated.next(false);
    localStorage.removeItem(StorageKeys.AUTH_TOKEN);
    this.jwtPayload = null;
  }

  getIdEmpresa(): number {
    return this.jwtPayload!.idempresa;
  }

  getEmpresa(): NomeResponse {
    const empresa = {
      id: this.jwtPayload!.idempresa,
      nome: this.jwtPayload!.empresa,
    };
    return empresa;
  }

  get isAuthenticated(): Observable<boolean> {
    const result = this._isAuthenticated.asObservable();
    return result;
  }

  private armazenarToken(token: string) {
    this.jwtPayload = this.jwtHelper.decodeToken(token);
    localStorage.setItem(StorageKeys.AUTH_TOKEN, token);
  }

  private setAuthState(
    authData: { token?: string; isAuthenticated: boolean },
    isRefresh: boolean = false
  ): void {
    if (authData.isAuthenticated && authData.token) {
      this.jwtPayload = this.jwtHelper.decodeToken(authData.token);
      window.localStorage.setItem(StorageKeys.AUTH_TOKEN, authData.token);
      this._isAuthenticated.next(authData.isAuthenticated);
      return;
    }
    this._isAuthenticated.next(authData.isAuthenticated);
  }

  private carregarToken() {
    const token = localStorage.getItem(StorageKeys.AUTH_TOKEN);

    if (token) {
      this.armazenarToken(token);
    }
  }

  encodeBase64 = (data: string) => {
    return this.Buffer.from(data).toString('base64');
  };
  decodeBase64 = (data: string) => {
    return this.Buffer.from(data, 'base64').toString('ascii');
  };

  isAdmin(): boolean {
    return this.havePermission('ROLE_ADMIN');
  }

  podeRealizarOperacao(rule: string): boolean {
    return this.havePermission(rule) || this.isAdmin();
  }

  havePermission(...permissions: string[]) {
    const authorities = this.jwtPayload?.authorities ?? [];
    return authorities.some((p) => permissions.includes(p));
  }

  get idempresa(): number {
    return this.jwtPayload!.idempresa!;
  }
}
